import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import Content, { HTMLContent } from '../components/Common/Content';
import PageTitle from '../components/Common/PageTitle';
import { specialsSubLinks } from '../constants/nav-links.const';
import SubNav from '../components/Layout/components/Navbar/components/SubNav';
import DynamicOffersList from '../components/Specials/DynamicOffersList';

export const SpecialsPageTemplate = ({ frontmatter, content, contentComponent, isPreviewMode }) => {
  const introComponent = contentComponent || Content;
  const title = frontmatter ? frontmatter.title : 'Specials';

  return (
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col col-12">
            <PageTitle title={title} content={content} contentComponent={introComponent}/>
          </div>
        </div>
      </div>
      <DynamicOffersList isPreviewMode={isPreviewMode}/>
    </section>
  );
};

SpecialsPageTemplate.propTypes = {
  frontmatter: PropTypes.object.isRequired,
  isPreviewMode: PropTypes.bool.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const SpecialsPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout title={post.frontmatter.title}>
      <div className={`sub-nav-wrapper`}>
        <SubNav subLinks={specialsSubLinks}/>
      </div>
      <SpecialsPageTemplate
        frontmatter={post.frontmatter}
        contentComponent={HTMLContent}
        content={post.html}
        isPreviewMode={false}
      />
    </Layout>
  );
};

SpecialsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SpecialsPage;

export const specialsPageQuery = graphql`
  query SpecialPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
