import newReleasesImage from '../img/new-releases.jpg';
import bestSellersImage from '../img/best-sellers.jpg';
import bestOffersImage from '../img/best-offers.jpg';
import acousticImage from '../img/acoustic.jpg';
import electricImage from '../img/electric.jpg';
import keyboardImage from '../img/keyboard.jpg';
import vocalsImage from '../img/vocals.jpg';
import ukuleleImage from '../img/ukulele.jpg';
import iconBusinessBill from '../img/icon-business-bill.svg';
import iconTagAlt from '../img/icon-tag-alt.svg';
import iconPercentage from '../img/icon-percentage.svg';
import iconAcousticGuitar from '../img/icon-acoustic-guitar.svg';
import iconElectricGuitar from '../img/icon-electric-guitar.svg';
import iconKeyboard from '../img/icon-keyboard.svg';
import iconVocals from '../img/icon-vocals.svg';
import iconUkulele from '../img/icon-ukulele.svg';

export const productAttribute = {
  title: 'Tags',
  topLevelFilters: true,
  options: [
    {
      key: '',
      value: '',
      title: 'New Releases',
      count: 0,
      checked: false,
      default: true,
      backgroundImage: newReleasesImage,
      icon: iconBusinessBill,
    },
    {
      key: 'tags',
      value: '4:1',
      title: 'Best Sellers',
      count: 0,
      checked: false,
      backgroundImage: bestSellersImage,
      icon: iconTagAlt,
    },
    {
      key: 'tags',
      value: '5:2',
      title: 'Best Offers',
      count: 0,
      checked: false,
      backgroundImage: bestOffersImage,
      icon: iconPercentage,
    },
    {
      key: 'instruments',
      value: '1',
      title: 'acoustic guitar',
      count: 0,
      checked: false,
      backgroundImage: acousticImage,
      icon: iconAcousticGuitar,
    },
    {
      key: 'instruments',
      value: '2',
      title: 'electric guitar',
      count: 0,
      checked: false,
      backgroundImage: electricImage,
      icon: iconElectricGuitar,
    },
    {
      key: 'instruments',
      value: '4',
      title: 'keyboard',
      count: 0,
      checked: false,
      backgroundImage: keyboardImage,
      icon: iconKeyboard,
    },
    {
      key: 'instruments',
      value: '5',
      title: 'vocals',
      count: 0,
      checked: false,
      backgroundImage: vocalsImage,
      icon: iconVocals,
    },
    {
      key: 'instruments',
      value: '3',
      title: 'ukulele',
      count: 0,
      checked: false,
      backgroundImage: ukuleleImage,
      icon: iconUkulele,
    }
  ],
};
