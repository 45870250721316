import './top-options-list.scss'
import PropTypes from 'prop-types';
import { isNotEmptyArray } from '../../../helpers/arrays.helper';
import React from 'react';
import TopOptionComponent from '../TopOption/TopOptionComponent';
import Loader from '../../Common/Loader/Loader';
import { isValidOption } from '../../../helpers/attributes.helper';

const TopOptionsList = ({topOptions, isLoader = false, className = '', isPreviewMode = false}) => {

  return (
    <div className={`top-options-wrapper position-relative ${className}`}>
      {isLoader && (<Loader hasBackground={true}/>)}
      {
        isNotEmptyArray(topOptions) && (
          <ul className={`list-unstyled row top-options-list mb-0 justify-content-xl-center`}>
            {
              topOptions.map((option, i) => {
                return isValidOption(option) && (
                  <li key={i} className={`mb-3 mb-lg-4 col col-6 col-sm-4 col-md-3 top-option-item`}>
                    <TopOptionComponent option={option} isPreviewMode={isPreviewMode}/>
                  </li>
                )
              })
            }
          </ul>
        )
      }
    </div>
  );
}

TopOptionsList.propTypes = {
  topOptions: PropTypes.array.isRequired,
  isLoader: PropTypes.bool,
  isPreviewMode: PropTypes.bool,
  className: PropTypes.string,
};

export default TopOptionsList;
